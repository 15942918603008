import axios from 'axios'

const validate = {
  //* 檢查姓名格式
  CheckName: function (name) {
    const nameString = /^.{0,5}$/
    // return nameString.test(name) ? true : '長度請勿超過 5 個字'
    if (nameString.test(name)) {
      return true
    }
  },
  // * 檢查 Email 格式
  CheckEmail: function (email) {
    const emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
    return emailRule.test(email) ? true : '請填入 Email 格式'
  },
  // * 檢查密碼格式
  CheckPwd: function (pwd) {
    // const pwdRule = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[0-9])(?!.*[<>'"/;` ,.%!@#$%^&*]).{6,}$/
    const pwdRule = /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x21-\x7e]).{6,}$/
    return pwdRule.test(pwd) ? true : '至少6碼且要英數字混合'
  },
  // * 密碼確認
  ConfirmPwd: function (confirmPwd, pwd) {
    if (confirmPwd === '') {
      return '確認密碼 為必填'
    }
    if (confirmPwd !== pwd) {
      return '與密碼不一致'
    }
    return true
  },
  // *新密碼確認
  CheckNewPwd: function (newPwd, pwd) {
    const pwdRule = /^(?=.*\d)(?=.*[a-zA-Z])(?!.*[^\x21-\x7e]).{6,}$/
    if (!pwdRule.test(newPwd)) {
      return '至少6碼且要英數字混合'
    }
    if (newPwd === pwd) {
      return '與原密碼一致'
    }
    return true
  },
  // *確認密碼
  ConfirmFinalPwd: function (confirm, pwd) {
    if (confirm === '') {
      return '確認密碼為必填'
    }
    if (confirm !== pwd) {
      return '與新密碼不一致'
    }
    return true
  },
  // * 新會員登入、手機號碼格式
  CheckPhoneNum: function (phoneNum) {
    const phoneNumRule = /^(09)[0-9]{8}$/
    return phoneNumRule.test(phoneNum) ? true : '請輸入正確的電話號碼'
  },
  // * 舊會員帳號登入驗證 (身分證/Email)
  CheckOldAccount: function (oldAccount) {
    // ? 身分證驗證
    const idRule = /^[a-z](1|2)\d{8}$/i
    // ? Email驗證
    const emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
    // ? 手機驗證
    const phoneNumRule = /^(09)[0-9]{8}$/
    // ? 三個驗證判斷
    if (idRule.test(oldAccount)) {
      return true
    }
    if (emailRule.test(oldAccount)) {
      return true
    }
    if (phoneNumRule.test(oldAccount)) {
      return true
    }
    return '請輸入正確的帳號'
  },
  // * 新舊會員帳號登入驗證 (身分證/Email/手機號碼)
  CheckAccount: function (Account) {
    // ? 身分證驗證
    const idRule = /^[a-z](1|2)\d{8}$/i
    // ? Email驗證
    const emailRule = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/
    // ? 手機驗證
    const phoneNumber = /^(09)[0-9]{8}$/
    // ? 三個驗證判斷
    if (idRule.test(Account)) {
      return true
    }
    if (emailRule.test(Account)) {
      return true
    }
    if (phoneNumber.test(Account)) {
      return true
    }
    return '請輸入正確的帳號'
  },
  // * 取得四位隨機驗證碼
  async GetValidateCode () {
    const url = `${process.env.VUE_APP_API}/api/members/ValidateCode`
    const res = await axios.get(url)
    const rtnData = {
      validateImg: '',
      transactionId: ''
    }
    rtnData.validateImg = res.data.info.captcha
    rtnData.transactionId = res.data.info.validTransactionId
    return rtnData
  }
}

export default validate
